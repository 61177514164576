
import { onSSR } from '@vue-storefront/core';
import { useContent } from '@vue-storefront/storyblok';
import {defineComponent, ssrRef, useContext, useMeta, useRoute} from '@nuxtjs/composition-api';
import { useCache } from '@vue-storefront/cache';
import RenderContent from '@/components/RenderContent.vue';
import {applyMetaFromContent, checkTimedPublishingFields} from '~/helpers/storyblok/storyblok';

export default defineComponent({
  components: {
    RenderContent,
  },
  layout: 'default',
  setup () {
    const {
      search: sbSearch,
      content: sbContent,
      loading: sbLoading,
    } = useContent();
    const { error } = useContext();
    const { addTags } = useCache();

    let slug = useRoute().value.params.pathMatch;
    console.log(slug);
    // Use storyblok home content as main page
    if (slug === undefined || slug === '') {
      slug = 'home';
    }

    const content = ssrRef({});

    onSSR(async () => {
      await sbSearch({ url: slug, cache: false });

      if (typeof sbContent.value !== 'object' || Array.isArray(sbContent.value)) {
        error({ statusCode: 404 });
        return;
      }

      // Check timed publishing fields on page object
      if (!checkTimedPublishingFields(sbContent.value)) {
        error({ statusCode: 404 });
        return;
      }

      addTags([
        { prefix: 'P', value: 'sb_content' },
      ]);

      content.value = sbContent.value;
    });

    useMeta();

    return {
      content,
      loading: sbLoading,
    };
  },
  head() {
    let meta = {
      title: null,
      meta: [],
      link: [],
    };
    // Apply meta info from storyblok page
    applyMetaFromContent(meta, this.content);
    return meta;
  },
});
